<template>
  <div class="container">
    <div class="login-container">
      <div class="login-form">
        <div class="title">
          新用户注册
          <router-link class="register-text" to="/login">已有账号<i class="el-icon-question"></i> </router-link>
        </div>
        <el-form ref="registerForm" label-width="80px" :rules="rules" :model="form">
          <el-form-item prop="account" label="账号">
            <el-input prefix-icon="el-icon-user" v-model="form.account" placeholder="账号" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item prop="password" label="密码">
            <el-input type="password" prefix-icon="el-icon-lock" v-model="form.password" placeholder="密码"
                      autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item prop="password2" label="确认密码">
            <el-input type="password" prefix-icon="el-icon-lock" v-model="form.password2" placeholder="确认密码"
                      autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item prop="name" label="姓名">
            <el-input prefix-icon="el-icon-user" v-model="form.name" placeholder="姓名" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item prop="phone" label="手机号">
            <el-input type="text" prefix-icon="el-icon-phone" v-model="form.phone" placeholder="手机号"
                      autocomplete="off"></el-input>
          </el-form-item>


          <div style="text-align: center">
            <el-button type="primary" @click="onSubmit" style="width: 100%;">注册</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'login',
    data () {
      const validatePassword = (rule, value, callback) => {
        if (value.length > 20 || value.length < 8) {
          callback(new Error('密码至少为8个字符'));
        } else {
          callback();
        }
      };
      const validateAccount = (rule, value, callback) => {
        if (value.length > 20 || value.length < 8) {
          callback(new Error('姓名至少为8个字符'));
        } else {
          callback();
        }
      };
      const validatePassword2 = (rule, value, callback) => {
        if (value.length > 20 || value.length < 8) {
          callback(new Error('确认至少为8个字符'));
        } else if (value !== this.form.password) {
          callback(new Error('两次密码不一样'));
        } else {
          callback();
        }
      };
      const validateName = (rule, value, callback) => {
        if (value.length > 20 || value.length < 2) {
          callback(new Error('姓名至少为2个字符'));
        } else {
          callback();
        }
      };
      const validatePhone = (rule, value, callback) => {
        if (!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(value)) {
          callback(new Error('请输入正确的手机号'));
        } else {
          callback();
        }
      };
      return {
        form: {
          account: '',
          password: '',
          password2: '',
          name: '',
          phone: ''
        },
        rules: {
          account: [
            { required: true, message: '请输入账号', trigger: 'blur' },
            { validator: validateAccount, trigger: 'blur' }
          ],
          password: [
            { required: true, message: '请输入密码', trigger: 'blur' },
            { validator: validatePassword, trigger: 'blur' }
          ],
          password2: [
            { required: true, message: '请输入确认密码', trigger: 'blur' },
            { validator: validatePassword2, trigger: 'blur' }
          ],
          name: [
            { required: true, message: '请输入姓名', trigger: 'blur' },
            { validator: validateName, trigger: 'blur' }
          ],
          phone: [
            { required: true, message: '请输入手机号', trigger: 'blur' },
            { validator: validatePhone, trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      onSubmit () {
        this.$refs.registerForm.validate((valid) => {
          if (valid) {
            this.$http
              .post('/index/register', this.form)
              .then(() => {
                this.$message.success('注册成功！')
                this.$router.push('/login')
              })
              .catch(err => {
                this.$message.error(err.message)
              })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    background-size: cover;
    background-image: url("../assets/images/banner.png");
    min-height: 100%;
    width: 100%;
    background-color: #2d3a4b;

    .login-container {
      /*position: relative;*/
      width: 1200px;
      margin: 0 auto;
    }

    .login-form {
      color: #666666;
      width: 400px;
      position: absolute;
      top: 50%;
      right: 9%;
      transform: translateY(-50%);
      padding: 25px 40px 35px;
      background-color: #ffffff;
      border-radius: 10px;
      box-shadow: 0 0 5px 0 #666666;

      ::v-deep .el-input__icon {
        font-size: 20px;
      }
    }

    .title {
      line-height: 60px;
      font-size: 21px;

      .register-text {
        text-decoration: none;
        color: #0085f0;
        cursor: pointer;
        position: absolute;
        right: 42px;
        top: 34px;
        font-size: 15px;
      }
    }

    .forget-text {
      text-decoration: none;
      text-align: right;
      margin-bottom: 15px;
      color: #999;
      width: 100%;
      float: right;
      display: inline-block;
    }
    a.forget-text:hover, .register-text:hover {
      text-decoration: underline;
      color: #0085f0;
      cursor: pointer;
    }
  }
</style>
